import React from "react";
import BaseComponent from "../../BaseComponent";

import "./CaseRecordEditorLandingComponent.css";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../common/AlertModal";
import TableComponent, {Column} from "../../common/TableComponent";
import {Link, NavLink} from "react-router-dom";
import UserSelectionDialogComponent from "../../users/UserSelectionDialogComponent";
import {Chronos} from "../../../entity/Chronos";

import fileIcon from "../../../assets/qinotify/img/icon_file.svg";
import historyIcon from "../../../assets/qinotify/img/icon_history.svg";
import CaseRecordFileUploadHistoryModalComponent from "./CaseRecordFileUploadHistoryModalComponent";
import {SectionItem} from "../../../entity/SectionItem";
import ClinicalRoleSelectionDialog from "../../users/ClinicalRoleSelectionDialog";
import LoadingSpinner from "../../common/LoadingSpinner";
import CaseRecordDeleteConfirmationModalComponent from "./CaseRecordDeleteConfirmationModalComponent";
import {CaseRecordPublicationQuestionDialog} from "./CaseRecordPublicationQuestionDialog";
import {BaseModalActions} from "../../alertmodal/BaseModal";
import {ImageUtil} from "../../../util/ImageUtil";
import {PatientUtil} from "../../../util/PatientUtil";
import {CommonUtil} from "../../../util/CommonUtil";

class CaseRecordEditorLandingComponent extends BaseComponent {

    requestCaseRecordId = undefined;

    uploadWidgets = [
        {
            id :API.caseRecordFileTypes.NELA,
            title : "NELA",
            label : "Upload File"
        },
        /*{
            id : API.caseRecordFileTypes.CRME,
            title : "CRME",
            label : "Upload File"
        },
        {
            id : API.caseRecordFileTypes.THDB,
            title : "TH DB",
            label : "Upload File"
        }*/
    ];

    constructor(props, context) {
        super(props, context);

        this.requestCaseRecordId = undefined;
        if (this.props.match.params !== undefined) {
            if (this.props.match.params.id !== undefined) {
                this.requestCaseRecordId = parseInt(this.props.match.params.id);
            }
        }

        let state = {
            user : this.getUser(),
            modalOpen : false,
            modalTitle : null,
            modalContent : null,
            modalButtons : null,
            userSelectionOpen : false,
            caseRecord : null,
            patientEditorOpen : this.requestCaseRecordId === undefined,
            caseRecordUsers : [],
            patientEditorForename : null,
            patientEditorSurname : null,
            patientEditorNHSNumber : null,
            patientEditorGender : -1,
            patientEditorAge : "",
            patientEditorLocationId : -1,
            patientEditorRecordId : null,
            patientEditorCaseRecordStageId : -1,
            addUserNetworkInFlight : false,
            missingClinicianNetworkInFlight : false,
            missingClinicians : null,
            missingClinicianId : undefined,
            locations : [],
            caseRecordStages : [],
            fileHistoryOpen : false,
            fileHistoryTitle : "",
            fileHistoryFileTypeId : null,
            clinicalRoles : [],
            clinicalRoleSelectionOpen : false,
            clinicalRoleSelectionCallback : undefined,
            clinicalRoleSelectionLabel : undefined,
            associatedNotifyNetworkInFlight : false,
            publishQuestionShown : false,
            publishQuestionQuestion : undefined,
            publishQuestionNegativeSelected : false,
            publishQuestions : [],
            publishQuestionIndex : 0,
            publishAnswers : []
        };

        // Add placeholders for each Widget upload file
        this.uploadWidgets.forEach((widget) => {
            state["uploadFile_" + widget.id] = undefined;
        });

        this.initState(state);

        this.fileUpload = React.createRef();

        this.getCaseRecord = this.getCaseRecord.bind(this);
        this.submitCaseRecord = this.submitCaseRecord.bind(this);
        this.discardCaseRecord = this.discardCaseRecord.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.requestFileUpload = this.requestFileUpload.bind(this);
        this.onUploadFileSelected = this.onUploadFileSelected.bind(this);
        this.showPatientDetailsEditor = this.showPatientDetailsEditor.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount() {
        let screenTitle = "Create a QIN-EmLap Case Review";

        if (this.requestCaseRecordId !== undefined) {
            screenTitle = "Edit Case Record";
            this.getCaseRecord(1, this.requestCaseRecordId); // TODO Department ID
        }

        this.props.title(screenTitle);
        this.fetchLocationsFromNetwork();
        this.fetchCaseRecordStagesFromNetwork();
    }

    onCaseRecordLoaded = (caseRecord) => {
        let patientForename = null;
        let patientSurname = null;
        let patientNHSNumber = null;
        let patientGenderId = 1;
        let patientAge = "";

        if (caseRecord.patient !== undefined) {
            patientForename = caseRecord.patient.givenName;
            patientSurname = caseRecord.patient.familyName;
            patientGenderId = caseRecord.patient.genderId;
            patientAge = caseRecord.patient.age;

            if (caseRecord.patient.hasOwnProperty("NHSNumber")) {
                patientNHSNumber = caseRecord.patient.NHSNumber;
            } else if (caseRecord.patient.hasOwnProperty("nhsNumber")) {
                patientNHSNumber = caseRecord.patient.nhsNumber;
            }
        }

        let caseRecordUsers = [];
        if (caseRecord.hasOwnProperty("associatedPeople")) {
            caseRecordUsers = caseRecord.associatedPeople;
        }

        let locationId = -1;
        if (caseRecord.hasOwnProperty("locationId")) {
            locationId = caseRecord.locationId;
        }

        let recordId = null;
        if (caseRecord.hasOwnProperty("recordId")) {
            recordId = caseRecord.recordId;
        }

        let caseRecordStageId = -1;
        if (caseRecord.hasOwnProperty("recordStageId")) {
            caseRecordStageId = caseRecord.recordStageId;
        }

        if (caseRecord.hasOwnProperty("segments")) {
            caseRecord.segments.forEach((segment) => {
                segment.percentage = this.getSegmentCompletionPercentage(segment);
            });
        }

        this.setState({
            caseRecord : caseRecord,
            patientEditorForename : patientForename,
            patientEditorSurname : patientSurname,
            patientEditorNHSNumber : patientNHSNumber,
            patientGender : patientGenderId,
            caseRecordUsers : caseRecordUsers,
            patientEditorLocationId : locationId,
            patientEditorRecordId : recordId,
            patientEditorCaseRecordStageId : caseRecordStageId,
            patientEditorAge : patientAge
        }, () => {
            this.fetchMissingClinicians();
        });


    }

    getCaseRecord(departmentId, caseRecordId) {
        if (departmentId === undefined && caseRecordId === undefined) {
            if (this.state.caseRecord !== undefined && this.state.caseRecord !== null) {
                departmentId = this.state.caseRecord.departmentId;
                caseRecordId = this.state.caseRecord.id;
            }
        }

        let formData = new FormData();
        formData.append("departmentId", departmentId);
        formData.append("caseRecordId", caseRecordId);
        formData.append("isDashboard", 1);
        formData.append("publishedOnly", "0");

        Axios.post(ENDPOINTS.caseRecord.getCaseRecord, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.onCaseRecordLoaded(resp.data.caseRecord);

                    if (resp.data.publication !== null) {
                        this.setState({
                            caseRecordPublication : resp.data.publication
                        });
                    }
                } else {
                    console.log(resp.error.desc + " [" + resp.error.code + "]");
                    console.log("SHOW MODAL");
                    this.showModal("Case Record Not Found", "There was a problem retrieving the Case Record. Click OK to return to the Case Record Database.", [
                        {
                            label : "OK",
                            click : () => {
                                this.props.history.push("/");
                            }
                        }
                    ]);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    submitCaseRecord = () => {
        if (this.state.submitNetworkInFlight) return;

        let fieldsToValidate = [
            "patientEditorForename",
            "patientEditorSurname",
            "patientEditorNHSNumber",
            "patientEditorRecordId",
            // "patientEditorGender",
            // "patientEditorLocationId"
        ];

        let validationSuccess = true;
        for (let i = 0; i < fieldsToValidate.length; i++) {
            let key = fieldsToValidate[i];
            if (this.state[key] == null || this.state[key] === "") {
                validationSuccess = false;
            }
        }

        if (!validationSuccess) {
            this.showError("Please provide all Patient Details fields.");
            return;
        }

        let formData = new FormData();
        formData.append("forename", this.state.patientEditorForename);
        formData.append("surname", this.state.patientEditorSurname);
        formData.append("nhsNumber", this.state.patientEditorNHSNumber);
        formData.append("recordId", this.state.patientEditorRecordId);
        // formData.append("age", this.state.patientEditorAge);
        // formData.append("caseRecordStageId", this.state.patientEditorCaseRecordStageId);
        //
        // let genderId = this.state.patientEditorGender;
        // if (genderId <= 0) {
        //     genderId = 1;
        // }
        //
        // formData.append("genderId", genderId);

        if (this.state.caseRecord !== undefined && this.state.caseRecord != null) {
            formData.append("caseRecordId", this.state.caseRecord.id);
        }

        // if (this.state.patientEditorLocationId > 0) {
        //     formData.append("locationId", this.state.patientEditorLocationId);
        // }

        this.setState({
            submitNetworkInFlight : true
        });

        Axios.post(ENDPOINTS.caseRecord.submitCaseRecord, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.caseRecord !== null) {
                        this.setState({
                            patientEditorOpen : false
                        }, () => {
                            this.onCaseRecordLoaded(resp.data.caseRecord);
                        });
                    }
                } else {
                    this.showError(resp.error.desc);
                }

                this.setState({
                    submitNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later.");

                this.setState({
                    submitNetworkInFlight : false
                });
            });
    }

    dismissPatientEditor = () => {
        this.setState({
            patientEditorOpen : false
        });
    }

    fetchLocationsFromNetwork = () => {
        let formData = new FormData();

        Axios.post(ENDPOINTS.location.getLocations, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let state = {
                        locations : resp.data.locations
                    };

                    if (this.state.patientEditorLocationId <= 0 && state.locations.length > 0) {
                        state.patientEditorLocationId = state.locations[0].id;
                    }

                    this.setState(state);
                } else {
                    console.log(resp.error.desc);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchCaseRecordStagesFromNetwork = () => {
        Axios.get(ENDPOINTS.caseRecord.getCaseRecordStages)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {

                    let state = {
                        caseRecordStages: resp.data.caseRecordStages
                    };

                    if (this.state.patientEditorCaseRecordStageId <= 0 && state.caseRecordStages.length > 0) {
                        state.patientEditorCaseRecordStageId = state.caseRecordStages[0].id;
                    }

                    this.setState(state);
                } else {
                    console.log(resp.error.desc);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    discardCaseRecord() {
        // TODO Confirmation
        this.setState({
            caseRecord : undefined
        });
    }

    requestFileUpload(id) {
        if (this.state.uploadInFlight) {
            console.log("UPLOADING, WILL NOT TRIGGER");
            return;
        }

        let state = {
            fileUploadId : id
        };

        state["uploadFile_" + id] = {
            id : id,
            file : null,
            isUploading : true,
            uploadSuccess : undefined,
            uploadError : undefined
        };

        this.setState(state);

        this.fileUpload.current.click();
    }

    onUploadFileSelected(e) {
        if (e.target.files.length > 0) {
            let file = e.target.files[0];

            let state = {
                uploadProgress : 0
            };

            let uploadId = "uploadFile_" + this.state.fileUploadId;
            let upload = this.state[uploadId];

            upload.file = file;

            state[uploadId] = upload;

            this.uploadFile(this.state.fileUploadId, file);
            this.setState(state);
        }
    }

    uploadFile(id, file) {
        if (this.state.uploadInFlight) return;
        this.setState({
            uploadInFlight : true,
        });

        let config = {
            onUploadProgress: (progressEvent) => {
                let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                let progress = Math.ceil((progressEvent.loaded / progressTotal) * 100);

                console.log("Progress " + progress);

                this.setState({
                    uploadProgress : progress
                });
            }
        };

        let formData = new FormData();
        formData.append("type", id);
        formData.append("caseRecordId", this.state.caseRecord.id);
        formData.append("file", file);

        Axios.post(ENDPOINTS.caseRecord.uploadCaseRecordFile, formData, config)
            .then((r) => {
                let uploadSuccess = false;

                let resp = API.parse(r);
                if (resp.success) {
                    uploadSuccess = true;

                    if (resp.data !== undefined) {
                        if (resp.data.file !== undefined) {
                            this.updateCaseRecordFile(resp.data.file);
                        }
                    }
                } else {
                    this.showModal(
                        "Error",
                        resp.error.desc + "[" + resp.error.code + "]"
                    );
                }

                let state = {
                    uploadInFlight : false
                };

                let uploadId = "uploadFile_" + this.state.fileUploadId;
                let uploadObj = this.state[uploadId];
                uploadObj.isUploading = false;
                uploadObj.uploadSuccess = uploadSuccess;

                state[uploadId] = uploadObj;

                this.setState(state, () => {
                    //Fetch case record again as we want to update section progress
                    this.getCaseRecord();
                });

                this.fileUpload.current.value = "";
            })
            .catch((e) => {
                console.log(e);

                this.showModal(
                    "Error",
                    "An unknown error has occurred. Please try again. [170-1]"
                );
            });
    }

    showError = (message) => {
        this.showModal("Error", message, [
            { label : "OK", click : () => {this.hideModal()} }
        ]);
    }

    showModal(title, message, buttons) {
        if (buttons === undefined) {
            buttons = [
                {
                    label : "OK",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal() {
        this.setState({
            modalOpen : false
        });
    }

    caseRecordUserRowWasClicked(row) {
        console.log(row);
    }

    showPatientDetailsEditor() {
        this.setState({
            patientEditorOpen : true
        });
    }

    formatPatientName = () => {
        let name = "";

        if (this.state.patientEditorForename !== undefined) {
            name = this.state.patientEditorForename + " ";
        }

        if (this.state.patientEditorSurname !== undefined) {
            name += this.state.patientEditorSurname;
        }

        return name;
    }

    showUserSelectionDialog = (keyword, callback, missingClinicianId) => {
        let state = {
            userSelectionOpen : true,
            userSelectionKeyword : keyword,
            userSelectionCallback : callback
        };

        if (missingClinicianId !== undefined) {
            state.missingClinicianId = missingClinicianId;
        }

        this.setState(state);
    }

    onUserRequestAddedToRecord = (user) => {
        if (user !== undefined) {
            if (user.clinicalRoleId !== null && user.clinicalRoleId > 0) {
                this.addUserToCaseRecord(user, user.clinicalRoleId);
            } else {
                this.promptForClinicalRole([
                    <span>Please select a Clinical Role for <strong>{user.givenName} {user.familyName}</strong>:</span>], (clinicalRoleId) => {
                    if (user !== undefined && clinicalRoleId !== undefined) {
                        this.addUserToCaseRecord(user, clinicalRoleId);
                    }

                    this.dismissClinicalRoleDialog();
                });
            }
        }

        this.setState({
            userSelectionOpen : false
        });
    }

    promptForClinicalRole = (label, callback) => {
        this.setState({
            clinicalRoleSelectionOpen : true,
            clinicalRoleSelectionLabel : label,
            clinicalRoleSelectionCallback : callback
        });
    }

    dismissClinicalRoleDialog = () => {
        this.setState({
            clinicalRoleSelectionOpen : false
        });
    }

    promptToRemoveUser = (user) => {
        this.showModal(
            "Confirmation",
            "Are you sure you want to remove " + user.givenName + " " + user.familyName + " from this Case Record?",
            [
                {
                    label : "Cancel",
                    click : () => {
                        this.hideModal();
                    }
                },
                {
                    label : "Remove",
                    style : "btn-danger",
                    click : () => {
                        this.hideModal();
                        this.removeUserFromCaseRecord(user);
                    }
                }
            ]
        );
    }

    addUserToCaseRecord = (user, clinicalRoleId, recordId) => {
        if (this.state.addUserNetworkInFlight) return;

        if (user === undefined) {
            return;
        }

        let userId = undefined;
        if (user.hasOwnProperty("userId")) {
            userId = user.userId;
        } else if (user.hasOwnProperty("id")) {
            userId = user.id;
        }

        if (userId === undefined) {
            return;
        }

        this.setState({
            addUserNetworkInFlight : true
        });

        let formData = new FormData();
        if (recordId !== undefined) {
            formData.append("id", recordId);
        }
        formData.append("caseRecordId", this.state.caseRecord.id);
        formData.append("userId", userId);
        formData.append("clinicalRoleId", clinicalRoleId);

        Axios.post(ENDPOINTS.caseRecord.addUserToCaseRecord, formData)
            .then((r) => {
                let state = {
                    addUserNetworkInFlight : false
                };

                let resp = API.parse(r);
                if (resp.success) {
                    state.caseRecordUsers = resp.data.users;
                } else {
                    this.showError(resp.error.desc);
                }

                this.setState(state);
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later");
            });
    }

    removeUserFromCaseRecord = (user) => {
        if (this.state.addUserNetworkInFlight) return;

        if (user === undefined) {
            return;
        }

        this.setState({
            addUserNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("caseRecordId", this.state.caseRecord.id);
        formData.append("userId", user.id);

        Axios.post(ENDPOINTS.caseRecord.removeUserFromCaseRecord, formData)
            .then((r) => {
                let state = {
                    addUserNetworkInFlight : false
                };

                let resp = API.parse(r);
                if (resp.success) {
                    state.caseRecordUsers = resp.data.users;
                } else {
                    this.showError(resp.error.desc);
                }

                this.setState(state)
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later.");
            });
    }

    fetchClinicalRoles = () => {
        if (this.state.clinicalRolesNetworkInFlight) return;

        this.setState({
            clinicalRolesNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.caseRecord.getClinicalRoles)
            .then((r) => {
                let clinicalRoles = [];

                let resp = API.parse(r);
                if (resp.success) {
                    clinicalRoles = resp.data.clinicalRoles;
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    clinicalRoles
                });
            })
            .then((e) => {
                console.log(e);
            });
    }

    fetchMissingClinicians = () => {
        if (this.state.missingClinicianNetworkInFlight) return;

        this.setState({
            missingClinicianNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("caseRecordId", this.state.caseRecord.id);

        Axios.post(ENDPOINTS.caseRecord.getMissingClinicians, formData)
            .then((r) => {
                let state = {
                    missingClinicianNetworkInFlight : false
                };

                let resp = API.parse(r);
                if (resp.success) {
                    state.missingClinicians = resp.data.missingClinicians;
                } else {
                    console.log("ERROR: " + resp.error.desc);
                }

                this.setState(state);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    missingClinicianWasResolved = (user) => {
        if (user !== undefined && user !== null) {
            if (user.clinicalRoleId !== null && user.clinicalRoleId > 0) {
                this.performClinicianResolution(user, user.clinicalRoleId);
            } else {
                this.promptForClinicalRole([
                    <span>Please select a Clinical Role for <strong>{user.givenName} {user.familyName}</strong>:</span>], (clinicalRoleId) => {
                    this.performClinicianResolution(user, clinicalRoleId)
                });
            }
        }

        this.setState({
            userSelectionOpen : false
        });
    }

    performClinicianResolution = (user, clinicalRoleId) => {
        if (user !== undefined && clinicalRoleId !== undefined) {
            this.addUserToCaseRecord(user, clinicalRoleId);

            if (this.state.missingClinicianId !== undefined) {
                this.removeMissingClinician(this.state.missingClinicianId);
            }
        }

        this.setState({
            missingClinicianId: undefined
        });

        this.dismissClinicalRoleDialog();
    }

    promptToRemoveMissingClinician = (missingClinician) => {
        if (missingClinician !== undefined) {
            this.showModal(
                "Confirmation",
                "Are you sure you wish to dismiss this Missing Clinician suggestion?",
                [
                    {
                        label : "Cancel",
                        click : () => this.hideModal()
                    },
                    {
                        label : "Dismiss",
                        style : "btn-danger",
                        click : () => {
                            this.hideModal();
                            this.removeMissingClinician(missingClinician.id);
                        }
                    }
                ]
            );
        }
    }

    removeMissingClinician = (missingClinicianId) => {
        let formData = new FormData();
        formData.append("caseRecordId", this.state.caseRecord.id);
        formData.append("missingClinicianId", missingClinicianId);

        Axios.post(ENDPOINTS.caseRecord.removeMissingClinician, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.setState({
                        missingClinicians : resp.data.missingClinicians
                    });
                } else {
                    console.log(resp.error.desc);

                    this.showError(resp.error.desc);
                }
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error occurred while trying to dismiss Missing Clinician suggestion. Please try again later.");
            });
    }

    getSegmentCompletionPercentage = (segment) => {
        let totalFieldsAndResults = 0;
        let completedFieldsAndResults = 0;

        if (segment.hasOwnProperty("sections")) {
            segment.sections.forEach((section) => {
                if (section.hasOwnProperty("inputs")) {
                    section.inputs.forEach((input) => {
                        totalFieldsAndResults++;
                        if (input.value !== null) {
                            completedFieldsAndResults++;
                        }
                    });
                }

                // if (section.hasOwnProperty("items")) {
                //     section.items.forEach((item) => {
                //         totalFieldsAndResults++;
                //
                //         if (parseInt(item.caseRecordSegmentSectionItemTypeId) === SectionItem.ITEM_TYPE_FIELD) {
                //             // originalValue will always be NULL for Fields
                //             if (item.value !== null && item.value !== "") {
                //                 completedFieldsAndResults++;
                //             }
                //         } else {
                //             if (item.originalValue != null) {
                //                 completedFieldsAndResults++;
                //             }
                //         }
                //     });
                // }
            });
        }

        if (totalFieldsAndResults > 0) {
            return ((completedFieldsAndResults / totalFieldsAndResults) * 100).toFixed(0);
        }
        return 0;
    }

    promptToDelete = () => {
        this.setState({
            deleteConfirmModalShown : true
        });
    }

    onDeleteDialogDismissed = (result) => {
        if (result) {
            console.log("DELETE WAS SUCCESS. MOVE TO HOME.");
            this.props.history.push("/");
        } else {
            this.setState({
                deleteConfirmModalShown : false
            });
        }
    }

    publishQuestionDidCallback = (action, data) => {
        if (action === BaseModalActions.CLOSE) {
            let newState = {
                publishQuestionShown : false
            };

            if (data) {
                let cancelled = false;
                if (data.hasOwnProperty("cancelled")) {
                    cancelled = data.cancelled === true;
                }

                if (!cancelled) {
                    let answer = {};

                    if (data.hasOwnProperty("question")) {
                        answer.questionId = data.question.id;
                        answer.questionText = data.question.message;
                    }

                    let answerPositive = true;
                    if (data.hasOwnProperty("option")) {
                        answer.optionId = data.option.id;
                        answer.optionText = data.option.title;

                        if (data.option.hasOwnProperty("positiveAction")) {
                            answerPositive = parseInt(data.option.positiveAction) === 1;
                        }
                    }

                    let currentAnswers = [...this.state.publishAnswers];
                    currentAnswers.push(answer);

                    let nextIndex = this.state.publishQuestionIndex + 1;
                    if (nextIndex >= this.state.publishQuestions.length) {
                        if (this.state.publishQuestionNegativeSelected || !answerPositive) {
                            // TODO Submit answers, do not submit Case Record
                            console.log("HIT NEGATIVE");
                            this.submitPublicationAnswersOverNetwork(currentAnswers);
                        } else {
                            // We're at the end of the questions, submit the publication again.
                            this.publishCaseRecordOverNetwork(currentAnswers);
                        }
                    } else {
                        // Otherwise, prompt for the next question
                        let nextQuestion = this.state.publishQuestions[nextIndex];

                        if (!answerPositive) {
                            newState.publishQuestionNegativeSelected = true;
                        }
                        newState.publishQuestionIndex = nextIndex;
                        newState.publishQuestionQuestion = nextQuestion;
                        newState.publishQuestionShown = true;
                    }
                }
            }

            this.setState(newState);
        }
    }

    getPatientDetailsSection() {
        let patientEditorElements = [];
        if (this.state.patientEditorOpen || this.state.caseRecord === undefined || this.state.caseRecord === null) {
            let admissionDates = "";

            let additionalButtons = [];
            if (this.state.caseRecord !== undefined && this.state.caseRecord !== null) {
                additionalButtons.push(<span>&nbsp;</span>);
                additionalButtons.push(<button className={"btn btn-secondary"} onClick={this.dismissPatientEditor}>Cancel</button>)

                if (this.state.user !== null) {
                    console.log(this.state.user);
                    if (parseInt(this.state.user.userRoleId) === API.userRoles.SUPER_ADMIN) {
                        additionalButtons.push(<span>&nbsp;&nbsp;&nbsp;</span>);
                        additionalButtons.push(<button className={"btn btn-danger"} onClick={this.promptToDelete}>Delete</button>);
                    }
                }
            }

            let submitButton = (<span className={"btn btn-success"} onClick={this.submitCaseRecord}>Save</span>);
            if (this.state.submitNetworkInFlight) {
                submitButton = (
                    <div className={"row justify-content-center mt-4"}>
                        <div className={"col-12 text-center"}>
                            Please wait...
                        </div>

                        <div className={"col-12 col-md-8 col-lg-6 mt-2"}>
                            <div className={"progress"}>
                                <div className={"progress-bar progress-bar-striped progress-bar-animated"} style={{width: "100%"}} />
                            </div>
                        </div>
                    </div>
                );
            }

            patientEditorElements = (
                <div className={"row"}>
                    <div className={"col-12 col-md-6 item-space"}>
                        <label>Forename</label>
                        <input type={"text"} className={"form-control"} name={"patientEditorForename"} value={this.state.patientEditorForename} onChange={this.handleChange} />
                    </div>

                    <div className={"col-12 col-md-6 item-space"}>
                        <label>Surname</label>
                        <input type={"text"} className={"form-control"} name={"patientEditorSurname"} value={this.state.patientEditorSurname} onChange={this.handleChange} />
                    </div>

                    <div className={"col-12 col-md-6 item-space"}>
                        <label>Record ID</label>
                        <input type={"text"} className={"form-control"} name={"patientEditorRecordId"} value={this.state.patientEditorRecordId} onChange={this.handleChange} />
                    </div>

                    <div className={"col-12 col-md-6 item-space"}>
                        <label>NHS Number</label>
                        <input type={"text"} className={"form-control"} name={"patientEditorNHSNumber"} value={this.state.patientEditorNHSNumber} onChange={this.handleChange} />
                    </div>

                    <div className={"col-12 text-center item-space"}>
                        {submitButton}
                        {additionalButtons}
                    </div>
                </div>
            )
            /*
            <div className={"col-12 col-md-6 item-space"}>
                        <label>Gender</label>
                        <select className={"form-control"} name={"patientEditorGender"} onChange={this.handleChange}>
                            <option value={1} selected={this.state.patientEditorGender == 1}>Male</option>
                            <option value={2} selected={this.state.patientEditorGender == 2}>Female</option>
                        </select>
                    </div>

                    <div className={"col-12 col-md-6 item-space"}>
                        <label>Age</label>
                        <input type={"number"} className={"form-control"} name={"patientEditorAge"} onChange={this.handleChange} value={this.state.patientEditorAge} />
                    </div>

                    <div className={"col-12 col-md-6 item-space"}>
                        <label>Location</label>
                        <select className={"form-control"} name={"patientEditorLocationId"} onChange={this.handleChange}>
                            {
                                this.state.locations.map((location) => {
                                    return (<option value={location.id} selected={location.id === this.state.patientEditorLocationId}>{location.name}</option>);
                                })
                            }
                        </select>
                    </div>

                    <div className={"col-12 col-md-6 item-space"}>
                        <label>Case Record Stage</label>
                        <select className={"form-control"} name={"patientEditorCaseRecordStageId"} onChange={this.handleChange}>
                            {
                                this.state.caseRecordStages.map((stage) => {
                                    console.log("STAGE: " + this.state.patientEditorCaseRecordStageId + " :: " + stage.id + " :: ? " + (this.state.patientEditorCaseRecordStageId == stage.id));
                                    return (<option value={stage.id} selected={this.state.patientEditorCaseRecordStageId == stage.id}>{stage.name}</option>);
                                })
                            }
                        </select>
                    </div>
             */

        } else {
            let patientEditorButton = [];
            if (this.state.user.visibility === API.visibility.FULL) {
                patientEditorButton = (
                    <span className={"btn btn-primary"} onClick={this.showPatientDetailsEditor}>Edit Details</span>
                );
            }

            patientEditorElements = (
                <div className={"row"}>
                    <div className={"col-12 col-md-8 col-lg-9 editor-title"}>
                        Patient Details
                        <h2>{this.formatPatientName()}</h2>
                    </div>

                    <div className={"col-12 col-md-4 col-lg-3 item-space text-right"}>
                        {patientEditorButton}
                    </div>

                    <div className={"col-12 item-space"}>
                        <span className={"record-stage"}>{this.state.caseRecord.recordStage}</span>
                    </div>

                    <div className={"col-6 col-md-4 item-space"}>
                        <label>Age: {CommonUtil.getOrDefault(this.state, "patientEditorAge", "---")}</label>
                    </div>
                    <div className={"col-6 col-md-4 item-space"}>
                        <label>Gender: {PatientUtil.getGenderLabel(this.state.caseRecord.patient.genderId)}</label>
                    </div>

                    <div className={"col-6 col-md-4 item-space"}>
                        <label>NHS Number: {this.state.patientEditorNHSNumber}</label>
                    </div>
                    <div className={"col-6 col-md-4 item-space"}>
                        <label>Record ID: {this.state.patientEditorRecordId}</label>
                    </div>

                    <div className={"col-6 col-md-4 item-space"}>
                        <label>Admission Date: {this.getOrDefault(this.state.caseRecord.patient, "admissionDate", "Currently not available")}</label>
                    </div>

                    <div className={"col-6 col-md-4 item-space"} />

                    <div className={"col-6 col-md-4 item-space"}>
                        <label>Surgery Date: {this.getOrDefault(this.state.caseRecord.patient, "surgeryDate", "Currently not available")}</label>
                    </div>
                    <div className={"col-6 col-md-4 item-space"}>
                        <label>Discharge Date: {this.getOrDefault(this.state.caseRecord.patient, "dischargeDate", "Currently not available")}</label>
                    </div>

                </div>
            )

            /*
            <div className={"col-6 col-md-4 item-space"}>
                        <label>Location: {this.state.caseRecord.locationName}</label>
                    </div>
             */
        }

        let editorFooter = [];
        if (this.state.caseRecordPublication) {
            if (parseInt(this.state.caseRecordPublication.published) === 0) {
                editorFooter = (
                    <div className={"card-footer text-light bg-primary d-flex align-items-center"}>
                        <div className={"flex-fill"}>
                            <h5>Publish Changes</h5>
                            <div>This Case Record has unpublished changes. Users will not see the latest Case Record changes until published.</div>
                        </div>
                        <button className={"btn btn-outline-light ml-1"} onClick={() => this.promptCaseRecordPublish()}>Publish Now</button>
                    </div>
                )
            } else {
                let publishDate = "---";
                if (this.state.caseRecordPublication.datePublished !== null) {
                    publishDate = Chronos.withTimestampSeconds(this.state.caseRecordPublication.datePublished).format("dd/MM/yyyy HH:mm");
                }

                editorFooter = (
                    <div className={"card-footer"}>
                        <h5>Changes published</h5>
                        <div>Case Record was published on {publishDate}</div>
                    </div>
                )
            }
        }

        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card patient-details-editor"}>
                        <div className={"card-body"}>
                            {patientEditorElements}
                        </div>
                        {editorFooter}
                    </div>
                </div>
            </div>
        );
    }

    findCaseRecordFile = (fileTypeId) => {
        if (this.state.caseRecord != null) {
            if (this.state.caseRecord.uploads !== undefined) {
                for (let i = 0; i < this.state.caseRecord.uploads.length; i++) {
                    let file = this.state.caseRecord.uploads[i];
                    if (file != null) {
                        if (file.caseRecordFileTypeId == fileTypeId) {
                            return file;
                        }
                    }
                }
            }
        }
        return null;
    }

    updateCaseRecordFile = (file) => {
        if (this.state.caseRecord != null) {
            let uploads = this.state.caseRecord.uploads || [];
            var found = false;

            for (let i = 0; i < uploads.length; i++) {
                if (uploads[i].caseRecordFileTypeId == file.caseRecordFileTypeId) {
                    uploads.splice(i, 1, file);
                    found = true;
                    break;
                }
            }

            if (!found) {
                uploads.push(file);
            }

            let caseRecord = this.state.caseRecord;
            caseRecord.uploads = uploads;

            this.setState({
                caseRecord
            });
        }
    }

    openFileHistoryModal = (fileTypeId) => {
        let title = "";
        if (fileTypeId === API.caseRecordFileTypes.NELA) {
            title = "NELA File History";
        } else if (fileTypeId === API.caseRecordFileTypes.CRME) {
            title = "CRME File History";
        } else if (fileTypeId === API.caseRecordFileTypes.THDB) {
            title = "TH DB File History";
        }

        this.setState({
            fileHistoryOpen : true,
            fileHistoryTitle : title,
            fileHistoryFileTypeId : fileTypeId
        });
    }

    dismissFileHistoryModal = () => {
        this.setState({
            fileHistoryOpen : false,
            fileHistoryFileTypeId : null
        });
    }

    onUpdateAssociatedUser = (record) => {
        this.promptForClinicalRole([<span>Please select a new role for <strong>{record.givenName} {record.familyName}</strong>:</span>], (clinicalRoleId) => {
            if (record !== undefined && clinicalRoleId !== undefined) {
                this.addUserToCaseRecord(record, clinicalRoleId, record.recordId);
            }

            this.dismissClinicalRoleDialog();
        });
    }

    viewUserWasClicked = (userId) => {
        this.props.history.push("/users/view/" + userId);
    }

    promptForConfirmationOfNotification = () => {
        this.showModal(
            "Confirmation",
            "Please confirm you wish to send Notifications to associated people.",
            [
                {
                    label : "Cancel",
                    click : () => this.hideModal()
                },
                {
                    label : "Send Notifications",
                    style : "btn-success",
                    click : () => {
                        this.notifyAssociatedUsersOverNetwork();

                        this.hideModal();
                    }
                }
            ]
        );
    }

    notifyAssociatedUsersOverNetwork = () => {
        if (this.state.associatedNotifyNetworkInFlight) return;
        this.setState({
            associatedNotifyNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("caseRecordId", this.state.caseRecord.id);

        Axios.post(ENDPOINTS.caseRecord.notifyUsersOfCaseRecordUpdate, formData)
            .then((r) => {
                let resp = API.parse(r);

                let success = false;

                if (resp.success) {
                    success = true;
                } else {
                    this.showError(resp.error.desc);
                }

                this.setState({
                    associatedNotifyNetworkInFlight : false,
                    associatedNotifySuccess : success
                });

                setTimeout(() => {
                    this.setState({
                        associatedNotifySuccess : undefined
                    });
                }, 15000);
            })
            .catch((e) => {
                console.log(e);

                // TODO Error
            });
    }

    promptCaseRecordPublish = () => {
        AlertModal.show(
            "Publish Case Record",
            [
                <p>Are you sure you want to publish this case record? Once published, users will see the latest Case Record. Published Case Records cannot be un-published.</p>,
                <p><div className={"alert alert-warning"}>Please confirm you have reviewed and updated all the relevant CRME information before publishing this review</div></p>
            ],
            [
                AlertModal.button(
                    "I confirm CRME data is up-to-date, Publish Now",
                    () => {
                        this.publishCaseRecordOverNetwork();
                        AlertModal.dismiss();
                    }
                ),
                AlertModal.button(
                    "I will review CRME data and return. Cancel publication",
                    () => {
                        AlertModal.dismiss()
                    }
                )
            ]
        );
    }

    publishCaseRecordOverNetwork = (answers) => {
        if (this.state.publishNetworkInFlight) return;
        this.setState({publishNetworkInFlight : true});

        const formData = new FormData();
        formData.append("caseRecordId", this.state.caseRecord.id);

        if (answers) {
            formData.append("caseRecordPublicationAnswers", JSON.stringify(answers));
        }

        Axios.post(ENDPOINTS.caseRecord.publishCaseRecord, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let requiresAction = false;
                    let publishQuestions = [];
                    if (resp.hasOwnProperty("data")) {
                        if (resp.data.hasOwnProperty("requiresAction")) {
                            requiresAction = resp.data.requiresAction;

                            if (resp.data.hasOwnProperty("questions")) {
                                publishQuestions = resp.data.questions;
                            }
                        }
                    }

                    if (!requiresAction) {
                        this.getCaseRecord(1, this.state.caseRecord.id);
                    } else {
                        // Prompt for questions
                        let currentQuestion = undefined;
                        if (publishQuestions.length > 0) {
                            currentQuestion = publishQuestions[0];
                        }

                        this.setState({
                            publishQuestions,
                            publishQuestionIndex : 0,
                            publishQuestionQuestion : currentQuestion,
                            publishQuestionNegativeSelected : false,
                            publishQuestionShown : true
                        });
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                this.setState({
                    publishNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    publishNetworkInFlight : false
                });
                AlertModal.showError(API.defaultError("CRP1000C"));
            });
    }

    submitPublicationAnswersOverNetwork = (answers) => {
        if (this.state.publicationAnswersNetworkInFlight) return;
        this.setState({ publicationAnswersNetworkInFlight : true });

        const formData = new FormData();
        formData.append("caseRecordId", this.state.caseRecord.id);
        formData.append("answers", JSON.stringify(answers));

        Axios.post(ENDPOINTS.caseRecord.submitCaseRecordPublicationAnswers, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    console.log("Sent option values");
                } else {
                    console.log(API.formatError(resp));
                }
                this.setState({ publicationAnswersNetworkInFlight : true });
            })
            .catch((e) => {
                console.log(e);
                this.setState({ publicationAnswersNetworkInFlight : true });
            });
    }

    render() {
        let alertModal = (
            <AlertModal
                open={this.state.modalOpen}
                title={this.state.modalTitle}
                content={this.state.modalContent}
                buttons={this.state.modalButtons}
                dismissHandler={this.hideModal} />
        )

        // If the Case Record is loading, show a spinner
        if (this.state.caseRecord === null && this.requestCaseRecordId !== undefined) {
            return (
                <div className="text-center">
                    <LoadingSpinner />
                    <strong>Please wait, loading...</strong>
                    {alertModal}
                </div>
            )
        }

        let whiteoutElement = [];
        if (this.state.caseRecord == null) {
            whiteoutElement = (
                <div className={"whiteout"}>
                    <div className={"whiteout-message"}>
                        Please complete Patient Details to begin
                    </div>
                </div>
            );
        }

        let segments = [];
        if (this.state.caseRecord != null) {
            if (this.state.caseRecord.segments !== undefined) {
                segments = this.state.caseRecord.segments;
            }
        }

        let missingClinicianTable = [];
        if (this.state.missingClinicians != null) {
            if (this.state.missingClinicians.length > 0) {
                missingClinicianTable = (
                    <div className={"missing-clinician-table"}>
                        <div className={"header-container"}>
                            <div className={"title"}>Missing Clinician Suggestions</div>
                        </div>

                        <TableComponent
                            className={"common-table alternating clickable"}
                            emptyMessage={"There are no missing clinicians"}
                            data={this.state.missingClinicians}>

                            <Column name={"givenName"} title={"Person Name"} render={(data, row) => {
                                return row.givenName + " " + row.familyName;
                            }} />
                            <Column name={"role"} title={"Staff Role"} />
                            <Column name={"id"} title={"Actions"} className={"text-center"} render={(data, row) => {
                                let buttons = [];
                                buttons.push(<span className={"btn btn-primary"} onClick={() => this.showUserSelectionDialog(row.givenName + " " + row.familyName, this.missingClinicianWasResolved, row.id)}>Resolve</span>);
                                buttons.push(<span>&nbsp;</span>);
                                buttons.push(<span className={"btn btn-danger"} onClick={() => this.promptToRemoveMissingClinician(row)}>Dismiss</span>);

                                return buttons;
                            }} />

                        </TableComponent>
                    </div>
                )
            }
        }

        let uploadWidgetElements = [];
        let assocPeopleTableActionColumn = [];
        let assocPeopleTableButtons = [];
        let segmentButtonLabel = "View";

        if (this.state.user.visibility === API.visibility.FULL) {
            segmentButtonLabel = "Add/Change Data";

            let hasUploads = false;
            let manualEntryWidget = [];

            let uploadWidgetItems = this.uploadWidgets.map((widget) => {
                let contentArea = null;
                let actionsArea = [];

                let showInfo = true;
                let fileUploaded = false;
                let fileError = null;

                if (this.state.hasOwnProperty("uploadFile_" + widget.id)) {
                    if (this.state["uploadFile_" + widget.id] !== undefined) {
                        let file = this.state["uploadFile_" + widget.id];

                        if (file.file !== null) {
                            if (file.isUploading) {
                                showInfo = false;

                                let uploadProgress = this.state.uploadProgress;
                                let uploadCaption = "Please wait, Uploading...";
                                if (uploadProgress >= 99) {
                                    uploadCaption = "Processing File...";
                                }

                                contentArea = (
                                    <div className={"progress-container"}>
                                        <div className="progress">
                                            <div className="progress-bar" style={{width: this.state.uploadProgress + "%"}} />
                                        </div>
                                        <div className={"caption"}>{uploadCaption}</div>
                                    </div>
                                )
                            } if (file.uploadSuccess) {
                                fileUploaded = true;
                            } else if (!file.uploadSuccess) {
                                fileError = "There was a problem uploading file";
                            }
                        }
                    }
                }

                if (showInfo) {
                    let file = this.findCaseRecordFile(widget.id);

                    let contentTitle = "No file uploaded";
                    let contentSubtitle = "";
                    let showHistory = false;

                    if (file != null) {
                        hasUploads = true;

                        contentTitle = file.originalFilename;
                        contentSubtitle = Chronos.withTimestampSeconds(file.dateCreated).format("dd/MM/yyyy HH:mm");
                        showHistory = true;
                    }

                    let history = [];
                    if (showHistory) {
                        history = (
                            <div className={"icon clickable"} style={{backgroundImage : "url(" + historyIcon + ")"}} onClick={() => this.openFileHistoryModal(widget.id)} />
                        )
                    }

                    contentArea = (
                        <div className={"subtitle"}>
                            <div className={"icon"} style={{backgroundImage : "url(" + fileIcon + ")"}} />
                            <div className={"content-area"}>
                                <div className={"content-title"}>{contentTitle}</div>
                                <div className={"content-subtitle"}>{contentSubtitle}</div>
                            </div>
                            {history}
                        </div>
                    )

                    let uploadButton = [];
                    if (this.state.uploadInFlight) {
                        uploadButton = (<span className={"btn btn-primary disabled"}>Please Wait</span>);
                    } else {
                        uploadButton = (<span className={"btn btn-primary"} onClick={() => this.requestFileUpload(widget.id)}>Upload file</span>);
                    }

                    actionsArea = (
                        <span className={"actions"}>
                            {uploadButton}
                        </span>
                    )
                }

                return (
                    <div key={"UPLOAD_WIDGET_" + widget.id} className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"card upload-widget"}>
                            <span className={"title"}><span className={"text"}>{widget.title}</span></span>
                            {contentArea}
                            {actionsArea}
                        </div>
                    </div>
                );
            });

            if (hasUploads) {
                manualEntryWidget = (
                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"card upload-widget"}>
                            <div className={"title"}>
                                <span className={"text"}>Manual Entry</span>
                            </div>
                            <div className={"subtitle"}>
                                <div className={"icon"} style={{backgroundImage : ImageUtil.background(fileIcon)}} />
                                <div className={"content-area"}>
                                    <div className={"content-title"}>Provide Manual Data Entry</div>
                                    <div className={"content-subtitle"}>&nbsp;</div>
                                </div>
                            </div>
                            <div className={"actions"}>
                                <NavLink to={"/record-manual-entry/" + this.state.caseRecord.id} className={"btn btn-primary"}>Enter</NavLink>
                            </div>
                        </div>
                    </div>
                )
            }

            uploadWidgetElements = (
                <div className={"row justify-content-center"}>
                    <div className={"input-hide"}>
                        <input type={"file"} ref={this.fileUpload} onChange={this.onUploadFileSelected} />
                    </div>

                    {uploadWidgetItems}

                    {manualEntryWidget}
                </div>
            )

            assocPeopleTableActionColumn = (
                <Column name={"id"} title={"Actions"} className={"text-center"} render={(data, row) => {
                    let buttons = [];
                    buttons.push(<span className="btn btn-primary" onClick={() => this.onUpdateAssociatedUser(row)}>Change Role</span>);
                    buttons.push(<span>&nbsp;</span>);
                    buttons.push(<span className={"btn btn-primary"} onClick={() => this.viewUserWasClicked(row.id)}>View</span>);
                    buttons.push(<span>&nbsp;</span>);
                    buttons.push(<span className={"btn btn-danger"} onClick={() => this.promptToRemoveUser(row)}>Remove</span>);

                    return buttons;
                }} />
            )

            if (this.state.associatedNotifyNetworkInFlight) {
                assocPeopleTableButtons.push(
                    <span className={"btn btn-secondary"}>Notifying Users...</span>
                )
            } else {
                if (this.state.associatedNotifySuccess === undefined) {
                    assocPeopleTableButtons.push(
                        <span className={"btn btn-primary"} onClick={this.promptForConfirmationOfNotification}>Notify Users</span>
                    )
                } else if (this.state.associatedNotifySuccess) {
                    assocPeopleTableButtons.push(
                        <span className={"btn btn-success"}>Noitified Users</span>
                    )
                } else {
                    assocPeopleTableButtons.push(
                        <span className={"btn btn-danger"}>Could not Notify!</span>
                    )
                }
            }

            assocPeopleTableButtons.push(
                <span className={"btn btn-primary"} onClick={() => { this.showUserSelectionDialog(undefined, this.onUserRequestAddedToRecord) }}>Add a person</span>
            );
        }

        return(
            <div className={"case-record-editor"}>

                { this.getPatientDetailsSection() }

                <div className={"whiteout-container"}>

                    {uploadWidgetElements}

                    <div className={"row user-table"}>
                        <div className={"col-12"}>
                            <div className={"card user-table-container"}>
                                <div className={"header-container"}>
                                    <div className={"title"}>Associated Clinicians</div>
                                    <div className={"action"}>
                                        {assocPeopleTableButtons}
                                    </div>
                                </div>

                                <TableComponent
                                    className={"common-table alternating clickable"}
                                    emptyMessage={"There are no associated clinicians"}
                                    data={this.state.caseRecordUsers}
                                    onRowClick={(row) => { this.caseRecordUserRowWasClicked(row, true);}}>

                                    <Column name={"givenName"} title={"Person Name"} className={"table-full-width"} render={(data, row) => {
                                        return row.givenName + " " + row.familyName;
                                    }} />
                                    <Column name={"role"} className={"table-full-width text-center"} title={"Staff Role"} />

                                    {assocPeopleTableActionColumn}

                                </TableComponent>

                                {missingClinicianTable}
                            </div>
                        </div>
                    </div>

                    <div className={"row record-segments"}>
                        <div className={"col-12"}>
                            <div className={"section-title"}>Manual Input (optional)</div>
                        </div>

                        { segments.map((item, index) => {
                            let segmentElements = [];
                            if (this.state.user.fullView == 1) {
                                segmentElements.push(<div className={"progress-title"}>{item.percentage}%</div>);
                                segmentElements.push(<div className={"progress-subtitle"}>of data complete</div>);
                            }

                            return (
                                <div className={"col-12 col-md-6 col-lg-4"}>
                                    <div className={"card segment"}>
                                        <div className={"colour-bar"} style={{ background : item.primaryColour }} />
                                        <div className={"title"}>{index + 1} - {item.segmentName}</div>
                                        {segmentElements}
                                        <div className={"action"}>
                                            <Link
                                                className={"btn btn-primary"}
                                                to={{
                                                    pathname: "/create/segment/" + index,
                                                    state: {
                                                        caseRecord: this.state.caseRecord
                                                    }
                                                }}>
                                                {segmentButtonLabel}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) }
                    </div>


                    {whiteoutElement}

                </div>

                <UserSelectionDialogComponent
                    open={this.state.userSelectionOpen}
                    callback={this.state.userSelectionCallback}
                    keyword={this.state.userSelectionKeyword} />

                <ClinicalRoleSelectionDialog
                    shown={this.state.clinicalRoleSelectionOpen}
                    callback={this.state.clinicalRoleSelectionCallback}
                    label={this.state.clinicalRoleSelectionLabel} />

                <CaseRecordFileUploadHistoryModalComponent
                    shown={this.state.fileHistoryOpen}
                    title={this.state.fileHistoryTitle}
                    caseRecordId={(this.state.caseRecord != null) ? this.state.caseRecord.id : null}
                    caseRecordFileTypeId={this.state.fileHistoryFileTypeId}
                    dismissCallback={this.dismissFileHistoryModal} />

                {alertModal}

                <CaseRecordDeleteConfirmationModalComponent
                    shown={this.state.deleteConfirmModalShown}
                    caseRecord={this.state.caseRecord}
                    callback={this.onDeleteDialogDismissed} />

                <CaseRecordPublicationQuestionDialog
                    shown={this.state.publishQuestionShown}
                    question={this.state.publishQuestionQuestion}
                    callback={this.publishQuestionDidCallback} />
            </div>
        )
    }

}

export default CaseRecordEditorLandingComponent;