import React from "react";
import BaseComponent from "../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import AlertModal from "../common/AlertModal";

import "./LoginComponent.css";
import {Link} from "react-router-dom";

import qilogo from "../../assets/qinotify/img/qinotify_logo.png";
import wmalogo from "../../assets/qinotify/img/wmahsn_logo.png";
import emlaplogo from "../../assets/qinotify/img/emlap_logo.png";
import dudleyNhsLogo from "../../assets/qinotify/img/dudly_logo.jpg";

import {Chronos} from "../../entity/Chronos";
import {DataManager} from "../../util/DataManager";
import {AuthUtil} from "../../util/AuthUtil";
import LoadingSpinner from "../common/LoadingSpinner";
import {ImageUtil} from "../../util/ImageUtil";

import iconSecure from "../../assets/qinotify/img/icon_encrypted_drop.svg";
import {TwoFactorCodeActions, TwoFactorCodeModal} from "./TwoFactorCodeModal";
import {BaseModalActions} from "../alertmodal/BaseModal";
import {Navigator} from "../../util/Navigator";
import {UrlUtil} from "../../util/UrlUtil";

class LoginComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.state = {
            username : null,
            password : null,
            networkInFlight : false,
            modalOpen : false,
            modalTitle : null,
            modalContent : null,
            modalButtons : null,
            showBanner : true,
            showSecureLogin : !!DataManager.getSecureLoginData(),
            supportsSecureLogin : false,
            tfaModalShown : false,
            tfaModalError : null,
            tfaForceDismiss : false
        };

        this.submitLogin = this.submitLogin.bind(this);
        this.showModal = this.showModal.bind(this);

        AuthUtil.supportsSecureLogin((support) => {
            this.setState({
                supportsSecureLogin : support
            });
        })
    }

    componentDidMount() {
        const hashValue = Navigator.getHashParam("iso");
        if (hashValue) {
            if (hashValue === "1") {
                setTimeout(() => {
                    AlertModal.show(
                        "Access Timed Out",
                        "Your access has timed out due to inactivity. Please log in again.",
                        [
                            AlertModal.button(
                                "OK",
                                () => {
                                    Navigator.navigate("/");
                                    AlertModal.dismiss();
                                }
                            )
                        ]
                    );
                }, 200);
            }
        }
    }

    submitLogin(e, code) {
        if (this.state.networkInFlight) return;

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        let validationResult = this.validateCreateFormData(this.state, [
            { key : "username", type : "string", label: "Username", postName : "emailAddress" },
            { key : "password", type : "string", label : "Password", postName : "password" }
        ]);

        if (validationResult) {
            this.setState({networkInFlight : true});

            let formData = validationResult.formData;
            formData.append("referer", "dashboard");

            if (code) {
                formData.append("code", code);
            }

            Axios.post(ENDPOINTS.login.submit, formData)
                .then((r) => {
                    let resp = API.parse(r);
                    this.handleLoginResponse(resp);
                    this.setState({networkInFlight : false});
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({networkInFlight : false});
                    AlertModal.showError("An error has occurred. Please try again later.");
                });
        } else {
            this.showModal("Error", "An unknown error has occurred. Please try again later.");
        }
    }

    show2FAModal = () => {
        this.setState({
            tfaModalShown : true,
            tfaModalError : null,
            tfaForceDismiss : false
        });
    }

    handleLoginResponse = (resp, fromSecureLogin) => {
        if (fromSecureLogin === undefined) {
            fromSecureLogin = false;
        }

        if (resp.success) {
            if (resp.data.hasOwnProperty("requiresAction")) {
                if (resp.data.requiresAction === true) {
                    console.log("Requires action!");
                    if (resp.data.hasOwnProperty("action") && resp.data.action) {
                        console.log("Action: ", resp.data.action);
                        if (resp.data.action.type === "2fa") {
                            console.log("show 2fa modal");
                            this.show2FAModal();
                        }
                    }
                }
                return;
            }

            if (!fromSecureLogin && this.state.supportsSecureLogin) {
                //Navigator.navigate("/secure-login-enroll");
                window.location.href = "/secure-login-enroll";
            } else {
                Navigator.navigate("/");
            }

            this.props.onLoginCallback(resp.data.user, resp.data.sessionToken);
        } else {
            AlertModal.showError(API.formatError(resp));
        }
    }

    showModal(title, message, buttons) {
        if (buttons === undefined) {
            buttons = [{label : "OK", click : () => { this.hideModal(); }}];
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal() {
        this.setState({
            modalOpen : false
        });
    }

    onInputKeyUp = (event) => {
        if (event.keyCode === 13) {
            this.submitLogin();
        }
    }

    twoFactorModalDidCallback = (action, data) => {
        if (action === BaseModalActions.CLOSE) {
            this.setState({
                tfaModalShown : false
            });
        } else if (action === TwoFactorCodeActions.SUBMIT) {
            this.submitLogin(undefined, data);
        }
    }

    requestSecureLoginOverNetwork = () => {
        const secureLoginData = DataManager.getSecureLoginData();
        if (!secureLoginData || !secureLoginData.identifier) return;

        if (this.state.networkInFlight) return;
        this.setState({networkInFlight : true});

        const formData = new FormData();
        formData.append("identifier", secureLoginData.identifier);

        Axios.post(ENDPOINTS.login.requestSecureLogin, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    AuthUtil.createSecureLoginResponse(resp.data.options, (result, data) => {
                        if (result) {
                            this.submitSecureLoginOverNetwork(data);
                        } else {
                            if (data) {
                                AlertModal.showError(data);
                            }
                            this.setState({networkInFlight : false});
                        }
                    });
                } else {
                    AlertModal.showError(API.formatError(resp));
                    this.setState({networkInFlight : false});
                }
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("SRL1000C"));
                this.setState({networkInFlight : false});
            });
    }

    submitSecureLoginOverNetwork = (info) => {
        const secureLoginData = DataManager.getSecureLoginData();
        if (!secureLoginData || !secureLoginData.identifier) return;

        const formData = new FormData();
        formData.append("info", info);
        formData.append("identifier", secureLoginData.identifier);

        Axios.post(ENDPOINTS.login.submitSecureLogin, formData)
            .then((r) => {
                const resp = API.parse(r);
                this.handleLoginResponse(resp, true);
                this.setState({networkInFlight : false});
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("SSL1000C"));
                this.setState({networkInFlight : false});
            });
    }

    render() {
        let topBanner = [];
        if (this.state.showBanner) {
            topBanner = (
                <div className={"top-banner"}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div>The main Dudley Group QI Notify-EmLap web-page is available <a href={"http://www.dgft.nhs.uk/about-us/quality/qi-notify-emlap/"}>here</a>, and our Privacy Statement is available <a href={"http://www.dgft.nhs.uk/qi-notify-emlap-privacy-statement/"}>here</a>.</div>
                                <div>Please take a moment to read and understand more about the solution and how we use your data.</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        let secureLoginButton = [];
        if (this.state.supportsSecureLogin && this.state.showSecureLogin) {
            let secureButton = (
                <button className={"btn btn-outline-light full-width secure-button"} onClick={() => this.requestSecureLoginOverNetwork()}>
                    <span className={"space"} />
                    <span className={"icon"} style={{backgroundImage : ImageUtil.background(iconSecure)}} />
                    <span>Secure Login</span>
                    <span className={"space"} />
                </button>
            );

            if (this.state.networkInFlight) {
                secureButton = (
                    <LoadingSpinner inline={true} small={true} inverse={true} />
                );
            }

            secureLoginButton = (
                <div className={"col-12 mt-4"}>
                    <div className={"card secure-login-container"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12 text-center"}>
                                    <h5>Secure Login</h5>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 text-center"}>
                                    Login with your fingerprint, face or pin.
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12 text-center"}>
                                    {secureButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        let loginButton = (<button className={"btn btn-primary"}>Login</button>);
        if (this.state.networkInFlight) {
            loginButton = (<LoadingSpinner small={true} inline={true} />)
        }

        return (
            <div>
                <div className={"login-screen"}>
                    {topBanner}

                    <div className={"container"}>
                        <div className={"row justify-content-center"}>
                            <div className={"col-12 col-md-8 col-lg-6"}>
                                <div className={"row piccadilly"}>
                                    <div className={"col-12"}>
                                        <div className={"qi-logo"} style={{backgroundImage : "url(" + qilogo + ")" }} />
                                    </div>
                                    <div className={"col-6"}>
                                        <div className={"small-logo"} style={{backgroundImage : "url(" + wmalogo + ")" }} />
                                    </div>
                                    <div className={"col-6"}>
                                        <div className={"small-logo"} style={{backgroundImage : "url(" + emlaplogo + ")" }} />
                                    </div>
                                </div>

                                <div className={"row mt-4 justify-content-center"}>
                                    <div className={"col-12"}>
                                        <div className={"alert alert-info text-center"}>
                                            <div><strong>This is the Admin web-portal for QI Notify-EmLap, and is only accessible to staff with admin rights.</strong></div>
                                            <div>If you have a user account you must login to QI Notify-EmLap via the App on your phone.</div>
                                        </div>
                                    </div>
                                </div>

                                {secureLoginButton}

                                <div className={"login-box"}>
                                    <form onSubmit={this.submitLogin}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <input type={"text"} className={"form-control text-center"} name={"username"} value={this.state.username} placeholder={"Email Address"} onChange={this.handleChange} />
                                            </div>
                                        </div>

                                        <div className={"row field-row"}>
                                            <div className={"col-12"}>
                                                <input type={"password"} className={"form-control text-center"} name={"password"} value={this.state.password} placeholder={"Password"} onChange={this.handleChange} />
                                            </div>
                                        </div>

                                        <div className={"row field-row"}>
                                            <div className={"col-12 submit-button"}>
                                                {loginButton}
                                                <Link to={"/forgot"}>Forgotten Password</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"spacer"} />

                    <div className={"footer"}>
                        <div><span className={"logo"} style={{backgroundImage : "url(" + dudleyNhsLogo +  ")"}} /></div>
                        <div>QI Notify&trade;</div>
                        <div>Copyright &copy; 2020-{Chronos.now().format("yyyy")} The Dudley Group NHS Foundation Trust. All rights reserved.</div>
                    </div>
                </div>

                <AlertModal
                    open={this.state.modalOpen}
                    title={this.state.modalTitle}
                    content={this.state.modalContent}
                    buttons={this.state.modalButtons} />

                <TwoFactorCodeModal
                    shown={this.state.tfaModalShown}
                    networkInFlight={this.state.networkInFlight}
                    email={this.state.username}
                    error={this.state.tfaModalError}
                    forceDismiss={this.state.tfaForceDismiss}
                    callback={this.twoFactorModalDidCallback} />
            </div>
        );
    }
}

export default LoginComponent;