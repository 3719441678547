import React, {useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import {AuthUtil} from "../../util/AuthUtil";
import AlertModal from "../common/AlertModal";
import {Chronos} from "../../entity/Chronos";
import {DataManager} from "../../util/DataManager";
import {ImageUtil} from "../../util/ImageUtil";

import "./SecureLoginPromptScreen.css";

import iconSecure from "../../assets/qinotify/img/icon_encrypted.svg";
import LoadingSpinner from "../common/LoadingSpinner";
import {Navigator} from "../../util/Navigator";

export const SecureLoginPromptScreen = (props) => {

    const [error, setError] = useState(null);
    const [registerSuccess, setRegisterSuccess] = useState(false);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    function moveOn() {
        let out = "/";

        let redirect = Navigator.getHashParam("r", null);
        if (redirect !== null) {
            out += redirect.replaceAll("~", "/");
        }

        Navigator.navigate(out);
    }

    function requestSecureLoginOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("source", "2");

        Axios.get(ENDPOINTS.login.requestSecureRegistration, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    AuthUtil.createSecureRegistrationResponse(
                        resp.data.options,
                        resp.data.challenge,
                        (success, data) => {
                            if (success) {
                                submitSecureLoginOverNetwork(resp.data.identifier, data);
                            } else {
                                if (data) {
                                    AlertModal.showError(data);
                                }
                            }
                        });
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                setError(API.defaultError("RSR1000C"));
            });
    }

    function submitSecureLoginOverNetwork(identifier, response) {
        const formData = new FormData();
        formData.append("identifier", identifier);
        formData.append("options", response);

        Axios.post(ENDPOINTS.login.registerSecureLogin, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    const expires = Chronos.now().add(3, Chronos.MONTHS).seconds();
                    DataManager.setSecureLoginData({
                        identifier : resp.data.identifier,
                        expires
                    });

                    setRegisterSuccess(true);
                } else {
                    console.log(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // RENDER

    let buttonElems = [
        <div className={"row mt-4"}>
            <div className={"col-12 text-center"}>
                <button className={"btn btn-secure"} onClick={() => requestSecureLoginOverNetwork()}>
                    <span className={"icon"} style={{backgroundImage : ImageUtil.background(iconSecure)}} />
                    Setup now
                </button>
            </div>
        </div>,

        <div className={"row mt-2"}>
            <div className={"col-12 text-center"}>
                <button className={"btn btn-outline-secondary"} onClick={() => moveOn()}>
                    Not now
                </button>
            </div>
        </div>
    ];

    if (networkInFlight) {
        buttonElems = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} small={true} />
                </div>
            </div>
        );
    }

    let mainContent = (
        <>
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <h5>Use Secure Login?</h5>
                </div>
            </div>

            <div className={"row mt-1"}>
                <div className={"col-12 text-center"}>
                    Make login quicker and safer on this device. Use your fingerprint, face or PIN to login to QI Notify.
                </div>
            </div>

            {buttonElems}
        </>
    );

    if (registerSuccess) {
        mainContent = (
            <>
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <h5>Secure Login</h5>
                    </div>
                </div>

                <div className={"row mt-1"}>
                    <div className={"col-12 text-center"}>
                        You're all set up. Look out for the Secure Login button the next time you login.
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn btn-success"} onClick={() => moveOn()}>
                            OK
                        </button>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={"secure-register-screen container app-screen animate-screen-content"}>

            <div className={"row justify-content-center mt-4"}>
                <div className={"col-12 col-lg-8 col-xl-6"}>

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"card"}>
                                <div className={"card-img-top secure-login-graphic-phrase"}>
                                    <div className={"secure-login-overlay"} style={{backgroundImage : ImageUtil.background(iconSecure)}} />
                                </div>

                                <div className={"card-body"}>
                                    {mainContent}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )

}